// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: require('../../package.json').version,
  production: false,
  environment: 'dev',
  apiBase: 'https://api.dev.recyclingapp.net',
  apiBaseAdmin: 'https://depotapi.dev.recyclingapp.net',
  Cognito: {
    domain:
      'direct-collect-dev-customer-domain.auth.ap-southeast-2.amazoncognito.com',
    userPoolId: 'ap-southeast-2_gQpkqBkdf',
    userPoolWebClientId: '4nq817gpekkmnj2p4i6uqpva59',
    redirectSignIn: 'https://d2w5r74olvnl53.cloudfront.net/user-home',
    redirectSignOut: 'https://d2w5r74olvnl53.cloudfront.net'
  },
  stripe_pk: 'pk_test_51LZ2OoJWH8z1ad0RUSmYUtu6xYhbPhRizIPEj9Q6PbYftO9PPu65Bqxf16e0xK76BOuWMJdimsXvA7HdTo8ZxEJr008NXJ8tdo'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
